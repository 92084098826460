exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beers-js": () => import("./../../../src/pages/beers.js" /* webpackChunkName: "component---src-pages-beers-js" */),
  "component---src-pages-ciders-js": () => import("./../../../src/pages/ciders.js" /* webpackChunkName: "component---src-pages-ciders-js" */),
  "component---src-pages-drinks-js": () => import("./../../../src/pages/drinks.js" /* webpackChunkName: "component---src-pages-drinks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-pourmasters-js": () => import("./../../../src/pages/pourmasters.js" /* webpackChunkName: "component---src-pages-pourmasters-js" */),
  "component---src-templates-beer-js": () => import("./../../../src/templates/Beer.js" /* webpackChunkName: "component---src-templates-beer-js" */),
  "component---src-templates-cider-js": () => import("./../../../src/templates/Cider.js" /* webpackChunkName: "component---src-templates-cider-js" */),
  "component---src-templates-pourmaster-js": () => import("./../../../src/templates/pourmaster.js" /* webpackChunkName: "component---src-templates-pourmaster-js" */)
}

